<template>
  <div class="advantages">
    <div class="wrapper">
      <div class="advantages__wrapper">
        <div class="advantages__header" data-aos="fade-up">
          <h6 class="advantages__subtitle subtitle">Advantages</h6>
          <h2 class="advantages__title title">Why us</h2>
        </div>
        <div class="advantages__list">
          <div
            class="advantages-item"
            v-for="item in advantages_data"
            :key="item.id"
            data-aos="fade-up"
          >
            <div
              class="advantages-item__header"
              v-lazy:background-image="require('../assets/images/advantages/' + item.background)"
            >
              <div class="advantages-item__icon">
                <img
                  loading="lazy"
                  :src="require('../assets/icons/' + item.image)"
                  :alt="item.title"
                />
              </div>
            </div>
            <p class="advantages-item__title">
              {{ item.title }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VAdvantages",
  data: () => ({
    advantages_data: [
      {
        id: 1,
        image: "calendar.svg",
        title: "Application acceptance 24/7",
        background: "back_1.png"
      },
      {
        id: 2,
        image: "people.svg",
        title: "Professional development team",
        background: "back_2.png"
      },
      {
        id: 3,
        image: "phone.svg",
        title: "Rapid feedback",
        background: "back_3.png"
      },
      {
        id: 4,
        image: "cycle.svg",
        title: "Full product development cycle",
        background: "back_4.png"
      },
      {
        id: 5,
        image: "clock.svg",
        title: "On-time product delivery",
        background: "back_5.png"
      },
      {
        id: 6,
        image: "Union.svg",
        title: "Cutting-edge technologies",
        background: "back_6.png"
      }
    ]
  })
};
</script>

<style lang="scss" scoped>
.advantages {
  background: var(--darkest-gray-color);
  &__wrapper {
    padding: 70px 8px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    @include for-tablet-portrait-up {
      padding: 70px 15px;
    }
    @include for-tablet-landscape-up {
      padding: 90px 16px;
    }
    @include for-desktop-up {
      padding: 120px 0;
    }
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    @include for-tablet-portrait-up {
      margin-bottom: 36px;
    }
    @include for-tablet-landscape-up {
      margin-bottom: 40px;
    }
    @include for-desktop-up {
      margin-bottom: 50px;
    }
    .subtitle {
      color: var(--medium-gray-color);
    }
    .title {
      color: var(--white-color);
    }
  }
  &__list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(162px, 1fr));
    grid-row-gap: 30px;
    grid-column-gap: 15px;
    justify-content: center;
    @include for-tablet-portrait-up {
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 16px;
    }
    @include for-tablet-landscape-up {
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 20px;
    }
    @include for-desktop-up {
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 29px;
    }
  }
  &-item {
    display: flex;
    flex-direction: column;
    &__title {
      color: var(--white-color);
      text-align: center;
      @include P3();
      @include for-tablet-portrait-up() {
        @include P5();
      }
      @include for-tablet-landscape-up() {
        @include P3();
      }
      @include for-desktop-up() {
        @include P2();
      }
    }
    &__header {
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: cover;
      height: 120px;
      margin-bottom: 16px;
      @include for-tablet-portrait-up {
        margin-bottom: 20px;
      }
      @include for-desktop-up {
        margin-bottom: 34px;
      }
    }
    &__icon {
      width: 100px;
      height: 100px;
      background: #1e2127;
      box-shadow: 0px 1px 14px rgba(174, 174, 174, 0.06);
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include for-tablet-portrait-up {
        width: 84px;
        height: 84px;
      }
      @include for-tablet-landscape-up {
        width: 100px;
        height: 100px;
      }
      img {
        height: 44px;
        width: 44px;
        @include for-tablet-portrait-up {
          width: 40px;
          height: 40px;
        }
        @include for-tablet-landscape-up {
          width: 44px;
          height: 44px;
        }
      }
    }
  }
}
</style>
