<template>
  <div class="media-about-us" style="overflow: hidden">
    <div class="wrapper">
      <div class="media-about-us__wrapper">
        <div class="media-about-us__content">
          <div class="media-about-us__header" data-aos="fade-right">
            <h6 class="media-about-us__subtitle subtitle">About us</h6>
            <h2 class="media-about-us__title title">In the news</h2>
          </div>
          <div class="media-about-us__text" data-aos="fade-right">
            <p>
              In this section, we will be sharing with you publications where
              our company, team or projects have mentioned.
            </p>
            <p>
              The article on our solutions (Otium Analytics and Otium
              E-commerce) in a special issue of "Delo" magazine.
              <span>
                December 2020.
              </span>
            </p>
          </div>
        </div>
        <div class="media-about-us__slider" data-aos="fade-left">
          <v-slider :settings="sliderSettings">
            <div
              class="slick-item"
              v-for="slider_item in sliderItems"
              :key="slider_item.id"
            >
              <picture>
                <source
                  :srcset="
                    require('../assets/images/media-about-us/' +
                      slider_item.img +
                      '.jpg') +
                      ' 1x, ' +
                      require('../assets/images/media-about-us/' +
                        slider_item.img +
                        '_2x' +
                        '.jpg') +
                      ' 2x'
                  "
                  type="image/jpeg"
                  media="(min-width: 768px)"
                />
                <source
                  :srcset="
                    require('../assets/images/media-about-us/' +
                      slider_item.img +
                      '_mob' +
                      '.jpg') +
                      ' 1x, ' +
                      require('../assets/images/media-about-us/' +
                        slider_item.img +
                        '_mob_2x' +
                        '.jpg') +
                      ' 2x'
                  "
                  type="image/jpeg"
                  media="(max-width: 767px)"
                />
                <img
                  :src="
                    require('../assets/images/media-about-us/' +
                      slider_item.img +
                      '.jpg')
                  "
                  alt="Media"
                  loading="lazy"
                />
              </picture>
            </div>
          </v-slider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VSlider from "../components/Carousel/VSlider";
export default {
  name: "VMediaAboutUs",
  components: { VSlider },
  data() {
    return {
      sliderItems: [
        {
          id: 1,
          img: "magazin_delo_about_us_1"
        },
        {
          id: 2,
          img: "magazin_delo_about_us_2"
        },
        {
          id: 3,
          img: "magazin_delo_about_us_3"
        }
      ],
      sliderSettings: {
        dots: false,
        slidesToScroll: 1,
        slidesToShow: 1,
        infinite: true,
        variableWidth: true
      }
    };
  }
};
</script>

<style lang="scss">
.media-about-us {
  background: var(--light-color);
  display: flex;
  overflow: hidden;
  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 70px 10px 114px 10px;
    @include for-tablet-portrait-up {
      padding: 70px 15px 136px 15px;
      flex-direction: row;
    }
    @include for-tablet-landscape-up() {
      padding: 90px 16px 156px 16px;
    }
    @include for-desktop-up() {
      padding: 120px 0 186px 0;
    }
  }
  &__slider {
    margin-left: -5px;
    width: 100vw;
    @include for-tablet-portrait-up() {
      margin-left: 0px;
    }
    .slider {
      .slick {
        &-slide {
          padding: 0 5px;
          height: 100%;
        }
        &-item {
          position: relative;
          width: 100%;
          height: 198px;
          min-width: 250px;
          max-width: 250px;
          outline: none;
          overflow: hidden;
          border-radius: 5px;
          @include for-tablet-portrait-up() {
            height: 238px;
            max-width: 302px;
            min-width: 302px;
            border-radius: 10px;
          }
          @include for-tablet-landscape-up() {
            height: 288px;
            max-width: 366px;
            min-width: 366px;
          }
          img,
          source {
            height: 100%;
            max-width: 100%;
            object-fit: cover;
            object-position: center;
            position: absolute;
            top: 0;
            left: 0;
            @include for-tablet-portrait-up {
              width: 100%;
            }
          }
        }
        &-list {
          padding: 0 25% 0 0 !important;
          @include for-tablet-portrait-up() {
            padding: 0 20% 0 0 !important;
          }
          @include for-tablet-landscape-up() {
            padding: 0 20% 0 0 !important;
          }
          @include for-tablet-landscape-up() {
            padding: 0 20% 0 0 !important;
          }
        }
        &-arrow {
          position: absolute;
          top: calc(100% + 30px);
          z-index: 10;
          @include carousel__btn-sm();
          &.slick-prev {
            left: 0;
          }
          &.slick-next {
            left: 40px;
            @include for-tablet-portrait-up {
              left: 58px;
            }
          }
        }
      }
    }
  }
  &__text {
    @include P4();
    line-height: 19px;
    margin-bottom: 20px;
    @include for-tablet-portrait-up() {
      line-height: 20px;
      margin-bottom: 0px;
    }
    @include for-tablet-landscape-up() {
      @include P3();
      line-height: 22px;
    }
    @include for-desktop-up() {
      line-height: 24px;
    }
    p + p {
      margin-top: 16px;
      @include for-tablet-portrait-up {
        margin-top: 14px;
      }
      @include for-desktop-up() {
        margin-top: 12px;
      }
    }
    p {
      span {
        display: block;
      }
    }
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    @include for-tablet-portrait-up {
      margin-bottom: 36px;
    }
    @include for-tablet-landscape-up() {
      margin-bottom: 40px;
    }
    @include for-desktop-up() {
      margin-bottom: 50px;
    }
    .subtitle {
      color: var(--gray-color);
      text-align: center;
    }
    .title {
      color: var(--black-color);
      text-align: center;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 20px);
    min-width: calc(100% - 20px);
    @include for-tablet-portrait-up {
      width: 41%;
      min-width: 41%;
      margin-right: 10px;
    }
    @include for-tablet-landscape-up {
      width: 41%;
      min-width: 41%;
    }
    @include for-desktop-up {
      width: 48%;
      min-width: 48%;
    }
  }
}
</style>
