<template>
  <div
    class="language__switcher"
    @mouseover="!isMobile ? showLanguageList() : {}"
    @mouseleave="!isMobile ? hideLanguageList() : {}"
  >
    <div class="current-language__item" @click="isActive = !isActive"><span> {{ current_language }} </span><span v-if="isMobile" :class="['arrow', isActive ? 'top' : 'bottom']"></span> </div>
    <transition name="language-list">
      <div class="language__list" v-if="isActive">
        <a
          v-for="(item, index) in language_items"
          :key="index"
          :href="item.value"
          >{{ item.label }}</a
        >
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      isActive: false
    };
  },
  props: {
    language_items: {
      type: Array,
      default: () => []
    },
    current_language: {
      type: String
    },
    isMobile: {
      type: Boolean
    }
  },
  methods:{
    showLanguageList(){
      this.isActive = true
    },
    hideLanguageList(){
      this.isActive = false
    }
  }
};
</script>

<style lang="scss" scoped>
.language-list-enter-active,
.language-list-leave-active {
  transition: transform 0.5s ease;
}

.language-list-enter,
.language-list-leave-to {
  transform: translateY(-20px);
  transition: transform 0.5s ease-in;
}

.language__switcher.desktop {
  position: relative;
  display: flex;
  align-items: center;
  .current-language__item {
    @include for-tablet-portrait-up {
      @include P3();
    }
    @include for-tablet-landscape-up {
      @include P2();
    }
    @include for-desktop-up {
      @include P1();
    }
    &:hover {
      cursor: pointer;
    }
  }
  .language__list {
    position: absolute;
    top: 100%;
    right: 0;
    background: rgba(14, 14, 14, 0.5);
    backdrop-filter: blur(30px);
    border-radius: 10px;
    padding: 16px;
    min-width: 147px;
    display: flex;
    flex-direction: column;
    @include for-tablet-landscape-up {
      min-width: 192px;
    }
    a {
      text-decoration: none;
      color: var(--white-color);
      @include for-tablet-portrait-up {
        @include P4();
      }
      @include for-tablet-landscape-up {
        @include P3();
      }
      @include for-desktop-up {
        @include P2();
      }
      &:hover {
        text-shadow: 0px 0px 0px rgba(255, 255, 255, 1);
      }
    }
    a + a {
      padding-top: 12px;
      @include for-tablet-landscape-up {
        padding-top: 14px;
      }
    }
  }
}

.language__switcher.mobile {
  margin-top: 40px;
  .current-language__item {
    margin-bottom: 12px;
    color: var(--white-color);
    @include P2();
    position: relative;
    display: flex;
    align-items: center;
    &:hover {
      text-shadow: 0px 0px 0px rgba(255, 255, 255, 1);
      cursor: pointer;
    }
    span:nth-child(1){
      margin-right: 8px;
    }
  }
  .language__list {
    display: flex;
    flex-direction: column;
    a {
      @include P3();
      text-decoration: none;
      color: var(--white-color);
      &:hover {
        text-shadow: 0px 0px 0px rgba(255, 255, 255, 1);
      }
    }
    a + a {
      padding-top: 10px;
    }
  }
}

.arrow{
  width: 10px;
  height: 10px;
  border-top: 2px solid var(--white-color);
  border-right: 2px solid var(--white-color);
  transition: all 0.5s ease;
  display: inline-block;
}
.arrow.top{
  transform: rotate(-45deg);
}
.arrow.bottom{
  transform: rotate(135deg);
}
</style>
