<template>
  <VueSlickCarousel v-bind="settings" class="slider">
    <slot></slot>
  </VueSlickCarousel>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'VSlider',
  components: {
    VueSlickCarousel
  },
  props: {
    settings: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss">
</style>
