<template>
  <div class="popup">
    <div class="wrapper">
      <div class="popup__wrapper">
        <div class="popup__content">
          <slot name="text"> </slot>
        </div>
        <div class="popup__button">
          <input type="checkbox" class="checkbox" v-model="isChecked" />
          <button type="button" @click="close" :disabled="!isChecked">
            <div class="button__label">OK</div>
            <div class="button__circle">
              <img
                src="../../assets/icons/arrow-next.svg"
                class="arrow"
                alt="Next arrow"
                loading="lazy"
              />
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VPopup",
  data: () => ({
    isChecked: false
  }),
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss">
.popup {
  position: fixed;
  bottom: 0;
  z-index: 11;
  width: 100%;
  background: rgb(7, 7, 7, 0.8);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.06);
  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
    @include for-tablet-portrait-up {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 14px 15px;
    }
    @include for-tablet-landscape-up {
      padding: 30px 15px;
    }
    @include for-desktop-up {
      padding: 30px 0;
    }
  }
  &__content {
    color: var(--white-color);
    text-align: center;
    @include P6();
    margin-bottom: 8px;
    @include for-tablet-portrait-up {
      @include P4();
      width: 70%;
      text-align: left;
      margin-bottom: 0;
    }
    @include for-tablet-landscape-up {
      @include P3();
    }
    a {
      text-decoration: underline;
      color: $white-color;
    }
  }
  &__button {
    display: flex;
    align-items: center;
    button {
      margin-left: 10px;
      @include popup__btn-primary();
      .button__circle {
        @include form-btn__circle();
        background: var(--white-color);
      }
      .button__label {
        display: flex;
        width: calc(100% - 32px);
        justify-content: center;
        text-align: center;
        @include for-tablet-portrait-up {
          width: calc(100% - 36px);
        }
        @include for-tablet-landscape-up {
          width: calc(100% - 40px);
        }
      }
    }
    input.checkbox {
      border: 1px solid var(--super-light-gray-color);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 4px;
      width: 26px;
      min-width: 26px;
      height: 26px;
      cursor: pointer;
      position: relative;
    }
    &::before {
      content: url("../../assets/icons/checkbox.svg");
      position: absolute;
      right: 50%;
      bottom: 8px;
      transform: translate(50%, 50%);
      opacity: 0;
      transition: 0.1s ease-in;
    }
    &:checked::before {
      opacity: 1;
    }
  }
}
</style>
