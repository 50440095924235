import axios from "axios"
import {API_URL} from '../config'
import { mutations, store } from '../store/app/store'
import router from '../router/index'

const sendForm = async (name, phone, email, comment) => {
    axios({
      method: 'post',
      url: API_URL,
      data:{
        source: 'otiumsoft.com',
        name: name,
        phone: phone,
        email: email,
        comment: comment
      }
    })
    .then((response) => {
      if (response.data.status === "success") {
        router.push("/modal/success");
        store.isRequestCorrect = true
        mutations.openModal()
        document.body.style.overflow = 'hidden'
      }
    })
    .catch((error) => {
      console.log(error.message);
      router.push("/modal/error");
      store.isRequestCorrect = false
      mutations.openModal()
      document.body.style.overflow = 'hidden'
    })
    .finally(() => {
      // this.preloader = false;
    });
}

export default {
    sendForm,
};