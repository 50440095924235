import Vue from 'vue'

export const store = Vue.observable({
  isHeaderScroll: false,
  isNavOpen: false,
  isModalOpen: false,
  isRequestCorrect: true,
  isCookiesPopupOpen: true
})

export const mutations = {
  toggleNav () {
    store.isNavOpen = !store.isNavOpen
  },
  openModal () {
    store.isModalOpen = true
  },
  closeModal () {
    store.isModalOpen = false
  },
  activeHeaderScroll(){
    store.isHeaderScroll = true
  },
  disactiveHeaderScroll(){
    store.isHeaderScroll = false
  },
  closeCookiesPopup(){
    store.isCookiesPopupOpen = false
  }
}
