<template>
  <div class="offer">
    <div class="wrapper">
      <div class="offer__wrapper">
        <!-- <div class="offer__card" data-aos="fade-up" data-aos-duration="3000">
          <v-form class="offer__form" />
        </div> -->
        <div class="offer__header" data-aos="fade-up" data-aos-duration="3000">
          <div class="offer__title">
            Effective software solutions to business across different
            industries!
          </div>
          <p class="offer__text text">
            We create mobile and web apps, websites, services from scratch to
            fit your business needs.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import VForm from "../components/Form/VForm";

export default {
  name: "VOffer",
  // components: { VForm }
};
</script>


<style lang="scss">
.offer {
  width: 100%;
  background: url("../assets/backgrounds/offer-background.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  &__wrapper {
    padding: 110px 10px 90px 10px;
    width: 100%;
    display: flex;
    align-items: center;
    // flex-direction: column-reverse;
    justify-content: center;
    @include for-tablet-portrait-up {
      padding: 190px 15px 170px 15px;
      flex-direction: row;
    }
    @include for-tablet-landscape-up {
      padding: 220px 16px 200px 16px;
    }
    @include for-desktop-up {
      padding: 220px 0 200px 0;
    }
  }
  &__card {
    background: rgba(14, 14, 14, 0.5);
    backdrop-filter: blur(30px);
    border-radius: 20px;
    width: 100%;
    max-width: 340px;
    padding: 30px 18px;
    @include for-tablet-portrait-up {
      width: 354px;
      max-width: 354px;
      padding: 46px 25px 50px 25px;
    }
    @include for-tablet-landscape-up {
      width: 390px;
      max-width: 390px;
      padding: 45px 27px;
    }
    @include for-desktop-up {
      width: 477px;
      max-width: 477px;
      padding: 45px 30px;
    }
  }
  &__form {
    width: 100%;
    display: flex;
    justify-content: center;
    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .form__group {
        @include form-group();
      }
      .form__group.form__group-checkbox {
        display: flex;
        flex-direction: row;
      }
      .label {
        @include form-label($white-color);
      }
      .label-checkbox {
        color: var(--white-color);
        a {
          color: var(--white-color);
        }
      }
      input[type="text"] {
        @include form-input($dark-gray-color, $white-color);
      }
      .checkbox {
        border: 1px solid var(--dark-gray-color);
      }
      textarea {
        @include form-textarea($dark-gray-color, $white-color);
      }
      .form__group + .form__group {
        margin-top: 9px;
      }
    }
    .form__title {
      @include P2();
      line-height: 29px;
      color: var(--white-color);
      margin-bottom: 30px;
      text-align: center;
      @include for-tablet-portrait-up {
        @include P1();
        line-height: 29px;
      }
      @include for-tablet-landscape-up {
        @include H6();
        line-height: 29px;
        margin-bottom: 48px;
      }
      @include for-desktop-up {
        @include H6();
        line-height: 29px;
        margin-bottom: 44px;
      }
    }
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 360px;
    margin-bottom: 38px;
    @include for-tablet-portrait-up {
      max-width: 60%;
      margin-bottom: 0px;
    }
    @include for-tablet-landscape-up {
      max-width: 60%;
      width: 60%;
    }
    @include for-desktop-up {
      max-width: 653px;
    }
    .offer__title + .text {
      margin-top: 20px;
      @include for-tablet-portrait-up {
        margin-top: 24px;
      }
      @include for-tablet-landscape-up {
        margin-top: 38px;
      }
      @include for-desktop-up {
        margin-top: 40px;
      }
    }
    .offer__title {
      color: var(--white-color);
      text-align: center;
      @include P1();
      @include for-tablet-portrait-up {
        // text-align: left;
        @include H5();
      }
      @include for-tablet-landscape-up {
        @include H2();
      }
      @include for-desktop-up {
        @include H1();
      }
    }
    .text {
      color: var(--light-gray-color);
      text-align: center;
      @include P4();
      @include for-tablet-portrait-up {
        // text-align: left;
      }
      @include for-tablet-landscape-up {
        @include P1();
      }
      @include for-desktop-up {
        @include H6();
      }
    }
  }
}
</style>