<template>
  <div id="app">
    <v-header :links="menu_links" />
    <router-view></router-view>
    <v-modal @close="closeModal" v-show="isModalOpen">
      <template #header v-if="isRequestCorrect">
        Thank you!
      </template>
      <template #header v-else>
        Error!
      </template>
      <template #body v-if="isRequestCorrect">
        Your message has been successfully sent. We will contact you very soon!
      </template>
      <template #body v-else>
        Please check the entered data!
      </template>
    </v-modal>
    <v-popup @close="closePopup" v-show="isCookiesOpen">
      <template #text>
        Dear User, We are continuously improving our website. We use cookies to
        collect the necessary information from you, and then we use it to
        improve the performance of the site, collect statistical information,
        and for marketing purposes. For more information and how to opt out from
        using cookies, please click
        <router-link
          to="/privacy-policy"
          class="footer__privacy_link"
        >
          here.
        </router-link>
      </template>
    </v-popup>
    <v-footer :links="menu_links" />
  </div>
</template>

<script>
import VHeader from "./components/VHeader";
import VFooter from "./components/VFooter.vue";
import VPopup from "./components/Modal/VPopup";
import { mutations, store } from "./store/app/store";
import router from "./router/index";

export default {
  name: "App",
  components: {
    VModal: () => import("./components/Modal/VModal"),
    VHeader,
    VFooter,
    VPopup
  },
  data: () => ({
    menu_links: [
      {
        name: "Services",
        anchor: "#services"
      },
      {
        name: "Company",
        anchor: "#about-company"
      },
      {
        name: "Portfolio",
        anchor: "#portfolio"
      },
      {
        name: "Contacts",
        anchor: "#managers"
      }
    ]
  }),
  computed: {
    isNavOpen() {
      return store.isNavOpen;
    },
    isModalOpen() {
      return store.isModalOpen;
    },
    isRequestCorrect() {
      return store.isRequestCorrect;
    },
    isCookiesOpen() {
      return store.isCookiesPopupOpen;
    }
  },
  methods: {
    closeModal() {
      router.push("/");
      mutations.closeModal();
      document.body.style.overflow = "auto";
    },
    closePopup() {
      mutations.closeCookiesPopup();
    }
  }
};
</script>

<style lang="scss">
.footer__privacy_link{
  &:hover{
    cursor: pointer;
  }
}
</style>
