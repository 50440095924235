<template>
  <div class="review">
    <div class="wrapper">
      <div class="review__wrapper" data-aos="fade-up">
        <v-slider :settings="sliderSettings">
          <div
            v-for="carousel_item in sliderItems"
            :key="carousel_item.id"
            class="carousel-item"
          >
            <div class="carousel-container">
              <img
                loading="lazy"
                src="../assets/images/review-quotes.png"
                alt="Quotes"
                class="item__quotes"
              />
              <img
                loading="lazy"
                class="item__image"
                :src="require('../assets/images/reviews/' + carousel_item.img)"
                :alt="carousel_item.name"
              />
              <div class="item__name">{{ carousel_item.name }}</div>
              <a :href="carousel_item.website" class="item__website">{{
                carousel_item.website
              }}</a>
              <div class="item__text">{{ carousel_item.text }}</div>
              <div class="item__author">{{ carousel_item.author }}</div>
            </div>
          </div>
        </v-slider>
      </div>
    </div>
  </div>
</template>

<script>
import VSlider from "../components/Carousel/VSlider";
export default {
  name: "VReviews",
  components: { VSlider },
  data() {
    return {
      sliderItems: [
        {
          id: 1,
          name: "Private Enterprise «Burshtat»",
          img: "burshtat-logo.png",
          website: "https://burshtat.by/",
          text:
            "“Individual approach was important for us when we were choosing IT partner. We made the right decision to choose OtiumSoft.OtiumSoft provided us with the handy tool for B2B sales which allows to effectively interact with costumers and simplify workflow of sales team. The high level of technical expertise made it possible to find suitable solutions and expeditiously implement them. Special thanks to our project manager, who was always keeping us updated with all the changings. We wish OtiumSoft team prosperity and can’t wait to work on new project.”",
          author: "Ilya Korolevich, CEO"
        },
        {
          id: 2,
          name: "MoiMagazinBel Ltd (moishop.by)",
          img: "moi-shop-logo.png",
          website: "https://moishop.by/",
          text:
            "“We trusted OtiumSoft to develop e-commerce platform of our shop. Young but experienced team of high-skilled IT specialists successfully coped with the task. They were always creative, enthusiastic and full of ideas in relation of the project. We are grateful to the entire team for the attentiveness to requests, the prompt feedback and the professional consultations throughout our cooperation. We can highly recommend OtiumSoft to be chosen as a reliable IT partner.” ",
          author: "Aleksei Mamtchits, CEO"
        }
      ],
      sliderSettings: {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: true,
        touchMove: false,
        pauseOnHover: true,
        pauseOnFocus: false,
        autoplay: true,
        autoplaySpeed: 6000,
        arrows: true
      }
    };
  }
};
</script>

<style lang="scss">
.review {
  width: 100%;
  background: var(--light-color);
  &__wrapper {
    width: 100%;
    padding: 50px 0;
    @include for-tablet-portrait-up {
      padding: 50px 30px;
    }
    @include for-tablet-landscape-up {
      padding: 70px 31px;
    }
    @include for-desktop-up {
      padding: 100px 0;
    }
    .slider {
      position: relative;
      &__content {
        display: flex;
        flex-direction: column;
      }
      .slick {
        &-arrow {
          position: absolute;
          top: 70px;
          z-index: 100;
          @include carousel__btn-bg();
          @include for-tablet-portrait-up {
            top: 50%;
          }
          &.slick-prev {
            left: 70%;
            @include for-tablet-portrait-up {
              left: -20px;
            }
            @include for-desktop-up {
              left: -25px;
            }
          }
          &.slick-next {
            right: 5%;
            @include for-tablet-portrait-up {
              right: -20px;
            }
            @include for-desktop-up {
              right: -25px;
            }
          }
        }
      }
      .carousel-item {
        border-radius: 16px;
        outline: none;
        &:active {
          outline: none;
        }
        &:focus {
          outline: none;
        }
      }
      .carousel-container {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 80px 10px 42px 10px;
        background: #ffffff;
        box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
        border-radius: 16px;
        margin: 20px;
        @include for-tablet-portrait-up {
          padding: 72px 74px 76px 74px;
        }
        @include for-tablet-landscape-up {
          padding: 60px 70px 83px 70px;
        }
        @include for-desktop-up {
          padding: 63px 102px 57px 102px;
        }
      }
      .item__quotes {
        position: absolute;
        width: 44px;
        top: 30px;
        @include for-tablet-portrait-up {
          top: 35px;
          left: 74px;
          width: 62px;
        }
        @include for-tablet-landscape-up {
          top: 40px;
          left: 40px;
          width: 68px;
        }
        @include for-desktop-up {
          top: 60px;
          left: 60px;
          width: 95px;
        }
      }
      .item__image {
        height: 60px;
        margin: 0 auto;
        width: auto;
        @include for-tablet-portrait-up {
          height: 90px;
        }
        @include for-desktop-up {
          height: 104px;
        }
        @include for-desktop-up {
          height: 122px;
        }
      }
      .item__name {
        color: var(--black-color);
        text-align: center;
        margin-top: 14px;
        @include P3-bold();
        line-height: 27px;
        @include for-tablet-portrait-up {
          margin-top: 17px;
          @include P2-bold();
          line-height: 27px;
        }
        @include for-desktop-up {
          margin-top: 20px;
        }
      }
      .item__website {
        color: var(--light-gray-color);
        display: flex;
        justify-content: center;
        text-decoration: none;
        margin-top: 6px;
        @include P4-light();
        @include for-tablet-portrait-up {
          @include P3-light();
        }
      }
      .item__text {
        color: var(--black-color);
        margin-top: 24px;
        @include P4();
        font-style: italic;
        line-height: 21px;
        @include for-tablet-portrait-up {
          font-style: italic;
          line-height: 24px;
          margin-top: 50px;
        }
        @include for-tablet-landscape-up {
          font-style: italic;
          line-height: 33px;
          margin-top: 60px;
        }
        @include for-desktop-up {
          @include P2();
          font-style: italic;
          line-height: 33px;
          margin-top: 34px;
        }
      }
      .item__author {
        text-align: right;
        color: var(--black-color);
        margin-top: 18px;
        @include P3();
        line-height: 33px;
        @include for-tablet-landscape-up {
          @include P2();
          line-height: 33px;
          margin-top: 32px;
        }
        @include for-desktop-up {
          margin-top: 30px;
        }
      }
    }
  }
}
</style>
