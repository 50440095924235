<template>
  <div class="footer">
    <div class="wrapper">
      <div class="footer__wrapper">
        <div class="footer__links">
          <div class="footer__column">
            <router-link :to="{ path: '/' }" class="footer__logo">
              <img
                src="../assets/logo.png"
                alt="Logo"
                class="logo__image"
                loading="lazy"
              />
              <div class="logo__title">OtiumSoft LLC</div>
            </router-link>
            <router-link
              to="/privacy-policy"
              v-if="!mobileView"
              class="footer__privacy"
            >
              Privacy policy
            </router-link>
            <div class="clutch-widget__container">
              <div
                class="clutch-widget"
                data-url="https://widget.clutch.co"
                data-widget-type="1"
                data-height="40"
                data-theme="white"
                data-clutchcompany-id="1729583"
              ></div>
            </div>
          </div>
          <nav class="footer__menu footer__column">
            <ul class="footer__list">
              <li
                class="footer__link"
                v-for="(item, index) in links"
                :key="index"
              >
                <router-link :to="{ path: '/', hash: item.anchor }">{{
                  item.name
                }}</router-link>
              </li>
            </ul>
          </nav>
          <div class="footer__contacts footer__column">
            <div class="footer__location location">
              <a
                href="https://goo.gl/maps/nvvqT2TLxfRqfGhR8"
                class="location__link"
                target="_blank"
              >
                <img
                  loading="lazy"
                  src="../assets/icons/location-footer.svg"
                  alt="location"
                  class="location__icon"
                />
                <div class="location__text">
                  <span> Zakharova 29A str., room 77e,</span>
                  <span> Minsk 220034, Belarus </span>
                </div>
              </a>
            </div>
            <div class="footer__email email">
              <a href="mailto:info@otiumsoft.com" class="email__link">
                <img
                  loading="lazy"
                  src="../assets/icons/email-footer.svg"
                  alt="email"
                  class="email__icon"
                />
                <div class="email__text">info@otiumsoft.com</div>
              </a>
            </div>
            <div class="footer__icons">
              <a
                class="footer__icons__link"
                href="https://www.facebook.com/otiumsoft"
              >
                <img
                  loading="lazy"
                  src="../assets/icons/facebook.svg"
                  alt="Facebook"
                />
              </a>
              <a
                class="footer__icons__link"
                href="https://www.linkedin.com/company/otiumsoft"
              >
                <img
                  loading="lazy"
                  src="../assets/icons/linkedin.svg"
                  alt="Linkedin"
                />
              </a>
              <a
                class="footer__icons__link"
                href="https://www.instagram.com/otium_soft/"
              >
                <img
                  loading="lazy"
                  src="../assets/icons/instagram.svg"
                  alt="Instagram"
                />
              </a>
              <a class="footer__icons__link" href="https://t.me/otiumsoft">
                <img
                  loading="lazy"
                  src="../assets/icons/telegram.svg"
                  alt="Telegram"
                />
              </a>
            </div>
          </div>
          <router-link
            to="/privacy-policy"
            v-if="mobileView"
            class="footer__privacy-mob"
          >
            Privacy policy
          </router-link>
        </div>
        <div class="footer__copyright">
          © 2020-2021. OtiumSoft LLC. All Right Reserved.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VFooter",
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    mobileView: true,
  }),
  methods: {
    handleView() {
      this.mobileView = window.innerWidth < 768;
    },
  },
  mounted() {
    let container = document.querySelector(".clutch-widget__container");
    let clutchScript = document.createElement("script");
    clutchScript.setAttribute("type", "text/javascript");
    clutchScript.setAttribute(
      "src",
      "https://widget.clutch.co/static/js/widget.js"
    );
    container.appendChild(clutchScript);
  },
  created() {
    this.handleView();
    window.addEventListener("resize", this.handleView);
  },
};
</script>

<style lang="scss">
.footer {
  background: var(--darkest-gray-color);
  color: var(--white-color);
  @include P4();
  line-height: 19px;
  @include for-tablet-landscape-up {
    @include P3();
    line-height: 25px;
  }
  @include for-desktop-up {
    @include P2();
    line-height: 25px;
  }
  line-height: 19px;
  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 30px 10px 13px 10px;
    @include for-tablet-portrait-up {
      padding: 40px 30px 13px 30px;
    }
    @include for-tablet-landscape-up {
      padding: 60px 30px 16px 30px;
    }
    @include for-desktop-up {
      padding: 60px 0 18px 0;
    }
  }
  &__links {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 16px;
    @include for-tablet-portrait-up {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding-bottom: 15px;
    }
    @include for-desktop-up {
      padding-bottom: 35px;
    }
  }
  &__copyright {
    display: flex;
    justify-content: center;
    color: var(--dark-gray-color);
    border-top: 1px solid var(--dark-gray-color);
    padding-top: 13px;
    @include P6();
    @include for-tablet-portrait-up {
      justify-content: flex-start;
      @include P4();
    }
    @include for-tablet-landscape-up {
      padding-top: 16px;
    }
    @include for-desktop-up {
      padding-top: 18px;
      @include P3();
    }
  }
  &__logo {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 22px;
    text-decoration: none;
    @include P4();
    line-height: 19px;
    @include for-tablet-portrait-up {
      margin-bottom: 10px;
      padding-top: 3px;
      align-items: flex-start;
    }
    @include for-tablet-landscape-up {
      @include P3();
      line-height: 25px;
    }
    @include for-desktop-up {
      @include P2();
      line-height: 25px;
      margin-bottom: 30px;
    }
    color: var(--white-color);
    &:visited {
      text-decoration: none;
    }
    &:active {
      text-decoration: none;
    }
  }
  .logo__image {
    width: 82px;
    height: 32px;
    @include for-tablet-portrait-up {
      width: 97px;
      height: 38px;
    }
    @include for-tablet-landscape-up {
      width: 112px;
      height: 44px;
    }
  }
  .logo__image + .logo__title {
    margin-top: 16px;
    @include for-tablet-portrait-up {
      margin-top: 26px;
    }
    @include for-tablet-landscape-up {
      margin-top: 30px;
    }
  }
  &__menu {
    display: none;
    @include for-tablet-portrait-up {
      display: flex;
    }
  }
  &__privacy {
    @include header-link-mobile();
    color: var(--white-color);
    @include P4();
    border-bottom: 1px solid var(--white-color);
    @include for-tablet-landscape-up {
      @include P3();
    }
  }
  &__privacy-mob {
    margin-top: 18px;
    @include header-link-mobile();
    color: var(--white-color);
    @include P4();
    border-bottom: 1px solid var(--white-color);
  }
  &__link {
    a {
      color: var(--white-color);
      @include header-link-mobile();
      @include P4();
      line-height: 19px;
      @include for-tablet-landscape-up {
        @include P3();
        line-height: 25px;
      }
      @include for-desktop-up {
        @include P2();
        line-height: 25px;
      }
    }
  }
  &__link + &__link {
    @include for-tablet-portrait-up {
      margin-top: 12px;
    }
  }
  &__contacts {
    display: flex;
    flex-direction: column;
    @include for-tablet-portrait-up {
      justify-content: flex-start;
      max-width: 268px;
    }
    @include for-tablet-landscape-up {
      max-width: 380px;
    }
    @include for-desktop-up {
      max-width: 424px;
    }
    div + div {
      margin-top: 16px;
      @include for-tablet-portrait-up {
        margin-top: 20px;
      }
    }
  }
  .location {
    display: flex;
    justify-content: center;
    @include for-tablet-portrait-up {
      justify-content: flex-start;
    }
    &__link {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      text-decoration: none;
      color: var(--white-color);
      @include P4();
      line-height: 19px;
      @include for-tablet-landscape-up {
        @include P3();
        line-height: 25px;
      }
      @include for-desktop-up {
        @include P2();
        line-height: 25px;
      }
      &:visited {
        text-decoration: none;
      }
      &:active {
        text-decoration: none;
      }
    }
    &__text {
      margin-left: 8.67px;
      text-align: center;
      @include for-tablet-portrait-up {
        text-align: left;
      }
      span {
        display: block;
      }
    }
    &__icon {
      width: 16px;
      padding-top: 3px;
      @include for-tablet-landscape-up {
        width: 22px;
        padding-top: 4px;
      }
    }
  }
  .email {
    display: flex;
    justify-content: center;
    @include for-tablet-portrait-up {
      justify-content: flex-start;
    }
    &__link {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      text-decoration: none;
      @include P4();
      line-height: 19px;
      @include for-tablet-landscape-up {
        @include P3();
        line-height: 25px;
      }
      @include for-desktop-up {
        @include P2();
        line-height: 25px;
      }
      color: var(--white-color);
      &:visited {
        text-decoration: none;
      }
      &:active {
        text-decoration: none;
      }
    }
    &__text {
      margin-left: 8.67px;
      text-align: center;
    }
    &__icon {
      width: 14.67px;
      padding-top: 3px;
      @include for-tablet-landscape-up {
        width: 20.17px;
        padding-top: 4px;
      }
    }
  }
  &__icons {
    display: flex;
    justify-content: center;
    @include for-tablet-portrait-up {
      justify-content: flex-start;
    }
    &__link {
      img {
        width: 22px;
        height: 22px;
        @include for-tablet-portrait-up {
          width: 20px;
          height: 20px;
        }
        @include for-tablet-landscape-up {
          width: 28px;
          height: 28px;
        }
        @include for-desktop-up {
          width: 30px;
          height: 30px;
        }
      }
    }
    &__link + &__link {
      margin-left: 10px;
      @include for-tablet-landscape-up {
        margin-left: 14px;
      }
      @include for-desktop-up {
        margin-left: 16px;
      }
    }
  }
}
.clutch-widget__container {
  margin: 16px auto;
  width: 50%;
  @include for-tablet-portrait-up {
    margin-top: 22px;
    margin-bottom: 0px;
    width: 100%;
  }
  @include for-tablet-landscape-up {
    margin-top: 24px;
  }
  @include for-desktop-up {
    margin-top: 26px;
  }
}
</style>
