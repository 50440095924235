<template>
  <div class="navbar">
    <ul class="menu">
      <li v-for="item in menu_links" :key="item.name" class="menu__item">
        <router-link :to="{ path: '/', hash: item.anchor }" class="menu__link">{{ item.name }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "VNavbar",
  props: {
    menu_links: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss">
.navbar {
  display: flex;
  align-items: center;
}

.menu {
  width: 429px;
  display: flex;
  justify-content: space-between;
  @include for-tablet-landscape-up {
    width: 560px;
  }
  &__link {
    @include header-link();
    @include P2();
    @include for-tablet-landscape-up {
      @include P1();
    }
  }
}
</style>
