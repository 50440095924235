<template>
  <div class="sidebar">
    <div
      class="sidebar-backdrop"
      @click="closeSidebarPanel"
      v-if="isPanelOpen"
    ></div>
    <transition name="slide">
      <div v-if="isPanelOpen" class="sidebar-panel">
        <ul class="menu-mobile">
          <li v-for="item in links" :key="item.name" class="menu__item-mobile">
            <router-link :to="{ path: '/', hash: item.anchor }" class="menu__link-mobile">{{ item.name }}</router-link>
          </li>
        </ul>
        <v-language-switcher
          class="mobile"
          :current_language="current_language"
          :language_items="language_items"
          :isMobile="true"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { store, mutations } from "../../store/app/store.js";
import VLanguageSwitcher from "./VLanguageSwitcher";

export default {
  components: { VLanguageSwitcher },
  props: {
    links: {
      type: Array,
      default: () => []
    },
    language_items: {
      type: Array,
      default: () => []
    },
    current_language: {
      type: String
    }
  },
  methods: {
    closeSidebarPanel() {
      mutations.toggleNav();
      document.body.style.overflow = store.isNavOpen ? "hidden" : "";
    }
  },
  computed: {
    isPanelOpen() {
      return store.isNavOpen;
    }
  }
};
</script>

<style lang="scss">
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
  transition: transform 0.5s ease-in;
}

.sidebar-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
}

.sidebar-panel {
  overflow-y: auto;
  background-color: var(--super-dark-gray-color);
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 999;
  padding: 3rem 20px 2rem 20px;
  width: 300px;
  .menu-mobile {
    display: flex;
    flex-direction: column;
    .menu__item-mobile + .menu__item-mobile {
      margin-top: 12px;
    }
    .menu__link-mobile {
      @include P2();
      @include header-link-mobile();
      color: var(--white-color);
    }
  }
}
</style>
