<template>
  <div class="sliders__container">
    <div class="sliders__container-item text" data-aos="fade-right">
      <VueSlickCarousel
        ref="text"
        :as-nav-for="$refs.images"
        v-bind="services_settings"
        class="text-slider"
      >
        <slot name="text-slider"></slot>
      </VueSlickCarousel>
    </div>
    <div class="sliders__container-item" data-aos="fade-left">
      <VueSlickCarousel
        ref="images"
        :as-nav-for="$refs.text"
        v-bind="images_settings"
        class="image-slider"
      >
        <slot name="image-slider"></slot>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "VTwoSliders",
  components: {
    VueSlickCarousel
  },
  props: {
    services_settings: {
      type: Object,
      default: () => {}
    },
    images_settings: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    this.$nextTick(this.$forceUpdate);
  }
};
</script>

<style></style>
