<template>
  <div class="about-us" id="about-company" style="overflow: hidden">
    <div class="wrapper">
      <div class="about-us__wrapper">
        <div class="about-us__header" data-aos="fade-up">
          <h6 class="about-us__subtitle subtitle">About us</h6>
          <h2 class="about-us__title title">About company</h2>
        </div>
        <div class="about-us__text">
          <div class="about-us__column" data-aos="fade-right">
            <p>
              <span>OtiumSoft</span> is a custom software development company.
            </p>
            <p>
              Our team is collaboration highly experienced business and IT
              professionals. Business professionals know a lot about problems
              companies face, IT professionals can offer ways to solve these
              problems with the help of cutting-edge technologies. We are not
              afraid of difficult tasks and ready to consider your most daring
              ideas.
            </p>
          </div>
          <div class="about-us__column" data-aos="fade-left">
            At the same time, we won't bore you with incomprehensible IT terms,
            since we can explain complex things in simple language. Transparency
            is <span>our basic work principle.</span> Throughout our
            cooperation, we are going to do our best to keep you updated on the
            status of project development at each stage. We offer a full cycle
            of product development from scratch, that is, you do not have to
            spend time looking for individual specialists to implement each of
            the stages.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VAboutUs"
};
</script>

<style lang="scss" scoped>
.about-us {
  background-color: var(--darkest-gray-color);
  &__wrapper {
    padding: 40px 10px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    @include for-tablet-portrait-up {
      padding: 50px 15px;
    }
    @include for-tablet-landscape-up {
      padding: 60px 16px;
    }
    @include for-desktop-up {
      padding: 70px 0;
    }
  }
  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    align-items: center;
    @include for-tablet-portrait-up {
      margin-bottom: 36px;
    }
    @include for-tablet-landscape-up {
      margin-bottom: 40px;
    }
    @include for-desktop-up {
      margin-bottom: 50px;
    }
    .subtitle {
      color: var(--medium-gray-color);
    }
    .title {
      color: var(--white-color);
      text-align: center;
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
    color: var(--white-color);
    @include P4();
    line-height: 19px;
    @include for-tablet-portrait-up() {
      line-height: 20px;
    }
    @include for-tablet-landscape-up() {
      @include P3();
      line-height: 23px;
    }
    @include for-desktop-up() {
      @include P3();
      line-height: 24px;
    }
    p {
      margin-bottom: 10px;
      @include for-tablet-portrait-up {
        margin-bottom: 0;
      }
    }
    span {
      color: var(--light-blue-color);
    }
    @include for-tablet-portrait-up {
      flex-direction: row;
    }
  }
  &__column {
    @include for-tablet-portrait-up {
      width: calc((100% - 34px) / 2);
    }
    @include for-tablet-landscape-up {
      width: calc((100% - 40px) / 2);
    }
    @include for-desktop-up {
      width: calc((100% - 45px) / 2);
    }
  }
  &__column + &__column {
    @include for-tablet-portrait-up {
      margin-left: 34px;
    }
    @include for-tablet-landscape-up {
      margin-left: 40px;
    }
    @include for-desktop-up {
      margin-left: 45px;
    }
  }
}
</style>
