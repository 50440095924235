<template>
  <div class="main">
    <v-offer />
    <v-services />
    <v-advantages />
    <v-portfolio />
    <v-industries />
    <v-consultation-form />
    <v-statistics />
    <v-reviews />
    <v-about-us />
    <v-media-about-us />
    <v-managers-form />
  </div>
</template>

<script>
import VOffer from '../components/VOffer.vue'
import VAdvantages from '../components/VAdvantages'
import VStatistics from '../components/VStatistics.vue'
import VReviews from '../components/VReviews.vue'
import VAboutUs from '../components/VAboutUs.vue'
import VMediaAboutUs from '../components/VMediaAboutUs.vue'
import VIndustries from '../components/VIndustries.vue'
import VServices from '../components/VServices.vue'
import VPortfolio from '../components/VPortfolio.vue'
import VManagersForm from '../components/VManagersForm.vue'
import VConsultationForm from '../components/VConsultationForm.vue'

export default {
  name: 'LandingPage',
  components: {
    VOffer,
    VAdvantages,
    VStatistics,
    VReviews,
    VAboutUs,
    VMediaAboutUs,
    VIndustries,
    VServices,
    VPortfolio,
    VManagersForm,
    VConsultationForm
  }
}
</script>

<style>
</style>
