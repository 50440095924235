<template>
  <div class="industries" id="industries">
    <div class="wrapper">
      <div class="industries__wrapper">
        <div class="industries__header" data-aos="fade-up">
          <h6 class="industries__subtitle subtitle">Industries</h6>
          <h2 class="industries__title title">
            We deliver software solutions for the following business industries
          </h2>
        </div>
        <div class="industries__list">
          <div
            class="industries-item"
            v-for="item in industries_data"
            :key="item.id"
            data-aos="fade-up"

          >
            <div class="industries-item__title">
              {{ item.title }}
            </div>
            <div class="industries-item__text">
              {{ item.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VIndustries',
  data: () => ({
    industries_data: [
      {
        id: 1,
        title: 'Industry #1',
        description: 'eCommerce'
      },
      {
        id: 2,
        title: 'Industry #2',
        description: 'Retail'
      },
      {
        id: 3,
        title: 'Industry #3',
        description: 'Logistics'
      },
      {
        id: 4,
        title: 'Industry #4',
        description: 'Finance'
      },
      {
        id: 5,
        title: 'Industry #5',
        description: 'Education & eLearning'
      },
      {
        id: 6,
        title: 'Industry #6',
        description: 'Healthcare'
      },
      {
        id: 7,
        title: 'Industry #7',
        description: 'Gaming'
      },
      {
        id: 8,
        title: 'Industry #8',
        description: 'Startups'
      }
    ]
  })
}
</script>

<style lang="scss" scoped>
.industries {
  background-color: var(--darkest-gray-color);
  &__wrapper {
    padding: 40px 10px 50px 10px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    @include for-tablet-portrait-up {
      padding: 50px 15px;
    }
    @include for-tablet-landscape-up {
      padding: 60px 16px;
    }
    @include for-desktop-up {
      padding: 70px 0;
    }
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    @include for-tablet-portrait-up {
      margin-bottom: 36px;
    }
    @include for-tablet-portrait-up {
      margin-bottom: 40px;
    }
    @include for-desktop-up {
      margin-bottom: 50px;
    }
    .subtitle {
      color: var(--medium-gray-color);
    }
    .title {
      color: var(--white-color);
      text-align: center;
    }
  }
  &__list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-row-gap: 14px;
    grid-column-gap: 10px;
    @include for-tablet-portrait-up {
      grid-template-columns: repeat(4, 1fr);
      grid-row-gap: 20px;
      grid-column-gap: 20px;
    }
  }
  &-item {
    border: 1px solid #44444f;
    box-sizing: border-box;
    border-radius: 5px;
    height: 120px;
    padding: 24px 12px 0 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include for-tablet-portrait-up {
      padding: 24px 15px 0 15px;
    }
    @include for-tablet-landscape-up {
      padding: 24px 3px 0 3px;
      height: 130px;
    }
    @include for-desktop-up {
      padding: 24px 23px 0 23px;
    }
    &__title {
      @include P7-bold();
      border-radius: 20px;
      color: var(--white-color);
      background: var(--dark-gradient-background);
      display: inline-block;
      padding: 4px 13px;
      margin-bottom: 14px;
      @include for-tablet-landscape-up {
        margin-bottom: 20px;
      }
    }
    &__text {
      display: flex;
      justify-content: center;
      color: var(--white-color);
      text-align: center;
      @include P4();
      @include for-tablet-landscape-up() {
        @include P3();
      }
      @include for-desktop-up() {
        @include P2();
      }
    }
  }
}
</style>
