var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"media-about-us",staticStyle:{"overflow":"hidden"}},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"media-about-us__wrapper"},[_vm._m(0),_c('div',{staticClass:"media-about-us__slider",attrs:{"data-aos":"fade-left"}},[_c('v-slider',{attrs:{"settings":_vm.sliderSettings}},_vm._l((_vm.sliderItems),function(slider_item){return _c('div',{key:slider_item.id,staticClass:"slick-item"},[_c('picture',[_c('source',{attrs:{"srcset":require('../assets/images/media-about-us/' +
                    slider_item.img +
                    '.jpg') +
                    ' 1x, ' +
                    require('../assets/images/media-about-us/' +
                      slider_item.img +
                      '_2x' +
                      '.jpg') +
                    ' 2x',"type":"image/jpeg","media":"(min-width: 768px)"}}),_c('source',{attrs:{"srcset":require('../assets/images/media-about-us/' +
                    slider_item.img +
                    '_mob' +
                    '.jpg') +
                    ' 1x, ' +
                    require('../assets/images/media-about-us/' +
                      slider_item.img +
                      '_mob_2x' +
                      '.jpg') +
                    ' 2x',"type":"image/jpeg","media":"(max-width: 767px)"}}),_c('img',{attrs:{"src":require('../assets/images/media-about-us/' +
                    slider_item.img +
                    '.jpg'),"alt":"Media","loading":"lazy"}})])])}),0)],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"media-about-us__content"},[_c('div',{staticClass:"media-about-us__header",attrs:{"data-aos":"fade-right"}},[_c('h6',{staticClass:"media-about-us__subtitle subtitle"},[_vm._v("About us")]),_c('h2',{staticClass:"media-about-us__title title"},[_vm._v("In the news")])]),_c('div',{staticClass:"media-about-us__text",attrs:{"data-aos":"fade-right"}},[_c('p',[_vm._v(" In this section, we will be sharing with you publications where our company, team or projects have mentioned. ")]),_c('p',[_vm._v(" The article on our solutions (Otium Analytics and Otium E-commerce) in a special issue of \"Delo\" magazine. "),_c('span',[_vm._v(" December 2020. ")])])])])}]

export { render, staticRenderFns }